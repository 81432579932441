import {
   Avatar,
   Badge,
   Box,
   Collapse,
   List,
   ListItem,
   ListItemButton,
   ListItemIcon,
   ListItemText,
   SvgIcon,
   Typography,
   styled,
   useMediaQuery,
   useTheme,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import SmsOutlinedIcon from "@mui/icons-material/SmsOutlined";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import DehazeIcon from "@mui/icons-material/Dehaze";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import { ThemeContext } from "../../theme";
import logo from "../../assets/images/easycrmLogo.png";
import { updateTotalNotification } from "../../store/slices/chatSlice";
import { setSidebar } from "../../store/slices/sideBarSlice";
import { useDispatch, useSelector } from "react-redux";
import SideBarList from "./SideBarList";
import userImage from "../../assets/images/user-img.svg";
import bellIcon from "../../assets/images/bell.svg";
import messages from "../../assets/images/messages.svg";
import { CgMenuRightAlt } from "react-icons/cg";
import { CgDarkMode } from "react-icons/cg";
import { userLogout } from "../../store/slices/authSlice";
import API from "../../config/axios";

const drawerWidth = 240;

const Drawer = styled(MuiDrawer, {
   shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
   width: drawerWidth,
   backgroundColor: theme.palette.background.sidebar,
   flexShrink: 0,
   whiteSpace: "nowrap",
   boxSizing: "border-box",
   ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
   }),
   ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
   }),
}));

const openedMixin = (theme) => ({
   width: drawerWidth,
   transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
   }),
   overflowX: "hidden",
   backgroundColor: theme.palette.background.sidebar,
});
const closedMixin = (theme) => ({
   transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
   }),
   overflowX: "hidden",
   backgroundColor: theme.palette.background.sidebar,
   width: `calc(${theme.spacing(7)} + 1px)`,
   [theme.breakpoints.up("sm")]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
   },
});
const DrawerHeader = styled("div")(({ theme }) => ({
   display: "flex",
   alignItems: "center",
   // justifyContent: "flex-end",
   // backgroundColor: "black",
   textAlign: "center",
   padding: theme.spacing(0, 1),
   // necessary for content to be below app bar
   ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
   shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
   // zIndex: theme.zIndex.drawer + 1,
   transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
      // backgroundColor: "black",
   }),
   ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
         easing: theme.transitions.easing.sharp,
         duration: theme.transitions.duration.enteringScreen,
         backgroundColor: theme.palette.background.sidebar,
         zIndex: "9999",
         // "& .MuiDrawer-docked .MuiDrawer-paper": {
         //   backgroundColor: "black",
         // },
      }),
   }),
}));

const CustomToolbar = styled(Toolbar)({
   backgroundColor: "#e0e3e7",
   height: "66px",
   justifyContent: "space-between",
   borderBottom: "1px solid #d5d5d5",

   "& .MuiPaper-root-MuiAppBar-root": {
      boxShadow: "none",
   },
});

const Sidebar = React.memo(() => {
   const [open, setOpen] = useState(true);
   const { totalNotification } = useSelector((state) => state?.chat);
   const { themeMode, switchThemeMode } = useContext(ThemeContext);

   const theme = useTheme();
   const isDarkMode = theme.palette.mode === "dark";

   const dispatch = useDispatch();
   const navigate = useNavigate();
   const [profile, setProfile] = useState({});
   const matches = useMediaQuery("(min-width:990px)");
   const closeSidebar = useMediaQuery("(max-width:990px");
   const { userName } = useSelector((state) => state.auth);

   const getBusinessProfile = async () => {
      try {
         const response = await API.get(`/user/business_profile`);
         setProfile(response.data.data[0]);
      } catch (err) {}
   };
   useEffect(() => {
      // dispatch(updateTotalNotification());
      getBusinessProfile();
   }, [dispatch]);

   const handleDrawer = () => setOpen(!open);
   const toggleDrawer = () => {
      dispatch(setSidebar());
   };
   const [openChannels, setOpenChannel] = useState(false);
   const [openSettings, setOpenSettings] = useState(false);
   const [openTemplates, setopenTemplates] = useState(false);
   const [openReports, setOpenReports] = useState(false);
   const [openInsights, setOpenInsights] = useState(false);

   const handleLogout = () => {
      dispatch(userLogout());
      navigate("/auth/sign-in");

      // navigate("/");
   };
   const handleOpenChannel = () => setOpenChannel(!openChannels);
   const hanldeOpenSettings = () => setOpenSettings(!openSettings);
   const hanldeOpenTemplates = () => setopenTemplates(!openTemplates);
   const handleOpenReports = () => setOpenReports(!openReports);
   const handleOpenInsights = () => setOpenInsights(!openInsights);

   // useEffect(() => {
   //    console.log(matches)
   //    if (matches) setOpen(false);
   // }, [matches]);

   return (
      <>
         <AppBar
            sx={{
               boxShadow: "none",
               "& .MuiToolbar-root": {
                  backgroundColor: "background.navbar",
                  border: "none",
               },
            }}
            // open={(open === true && !closeSidebar) || open}
            open={open}
         >
            <CustomToolbar>
               <Box sx={{ display: "flex" }}>
                  {matches && (
                     <Box
                        sx={{
                           // backgroundColor: "black",
                           marginRight: 5,
                           ...(open && { display: "none" }),
                        }}
                     >
                        {/* <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawer}
                edge="start"
                sx={{
                  marginRight: 5,
                  ...(open && { display: "none" }),
                }}
              > */}

                        {/* launching soon ginger logo */}
                        {/* <div className="launchingsoon_logo"></div>  */}
                        {/* launching soon ginger logo */}

                        {/* <MenuIcon /> */}
                        {/* </IconButton> */}
                     </Box>
                  )}
                  {matches && (
                     <IconButton
                        onClick={handleDrawer}
                        sx={{
                           color: "text.primary",
                           paddingLeft: "20px",
                           "&:hover": {
                              backgroundColor: "transparent",
                              "& .left-right": {
                                 color: "#0292d0",
                              },
                           },
                        }}
                     >
                        <FontAwesomeIcon
                           className="left-right"
                           icon="fa-solid fa-arrow-right-arrow-left"
                           sx={{ color: "primary.main" }}
                        />
                     </IconButton>
                  )}
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                     <Avatar
                        src={profile?.profile_picture_url || userImage}
                        sx={{
                           width: "50px",
                           height: "48px",
                           marginLeft: "2px",
                           backgroundColor: "background.profilebgchat",
                           color: "#fff",
                           "& img": {
                              width: profile?.profile_picture_url
                                 ? "100%"
                                 : "41%",
                              height: profile?.profile_picture_url
                                 ? "100% "
                                 : "55%",
                           },
                        }}
                     />
                     {matches && (
                        <Box mx={1}>
                           <Typography
                              sx={{
                                 color: "text.primary",
                                 textTransform: "capitalize",
                              }}
                           >
                              Welcome Back {userName||"User"}
                           </Typography>
                           <Typography
                              sx={{
                                 color: "black",
                                 fontWeight: "400",
                                 fontSize: "13px",
                              }}
                           >
                              {/* gingerconsultancyservice.com */}
                           </Typography>
                        </Box>
                     )}
                  </Box>
               </Box>

               <Box
                  sx={{
                     display: "flex",
                     alignItems: "center",
                     width: "200px",
                     justifyContent: {
                        xs: "flex-end",
                        sm: "flex-end",
                        md: "center",
                     },
                     gap: 1,
                     //   paddingRight: { xs: '18px', sm: '32px', md: 0 },
                     // marginTop: matches ? "10px" : "10px",
                     "@media (min-width: 600px)": {
                        paddingRight: "14px",
                        justifyContent: "flex-end",
                     },
                     "@media (max-width: 600px)": {
                        paddingRight: "11px",
                        justifyContent: "flex-end",
                     },
                     "@media (min-width: 601px) and (max-width: 849px)": {
                        paddingRight: "9px",
                        justifyContent: "flex-end",
                     },
                     "@media (min-width: 850px)": {
                        paddingRight: "0",
                        justifyContent: "center",
                     },
                  }}
               >
                  <IconButton
                     sx={{
                        backgroundColor: "#0293D2",
                        ":hover": {
                           backgroundColor: "background.iconhover",
                        },
                     }}
                     onClick={() => switchThemeMode()}
                  >
                     {themeMode === "dark" ? (
                        <Brightness7Icon />
                     ) : (
                        <Brightness4Icon color="#fff" />
                     )}
                  </IconButton>

                  <Badge
                     badgeContent={totalNotification}
                     sx={{
                        "& .MuiBadge-badge": {
                           backgroundColor: "red",
                           color: "#fff",
                           top: "4px",
                           right: "2px",
                        },
                     }}
                  >
                     <IconButton
                        onClick={() => navigate("/chat")}
                        sx={{
                           backgroundColor: "#0293D2",
                           padding: "11px 11px",
                           ":hover": {
                              backgroundColor: "background.iconhover",
                           },
                        }}
                     >
                        {/* <SmsOutlinedIcon sx={{ color: "#fff" }} /> */}
                        <img
                           src={messages}
                           alt="Messages"
                           style={{
                              width: "18px",
                              height: "18px",
                              paddingTop: "0.5px",
                           }}
                        />
                     </IconButton>
                  </Badge>

                  <IconButton
                     sx={{
                        backgroundColor: "#0293D2",
                        padding: "11px 11px",
                        ":hover": {
                           backgroundColor: "background.iconhover",
                        },
                     }}
                  >
                     {/* <NotificationsNoneOutlinedIcon sx={{ color: "#fff" }} /> */}
                     <img
                        src={bellIcon}
                        alt="Notifications"
                        style={{
                           width: "18px",
                           height: "18px",
                        }}
                     />
                  </IconButton>

                  {!matches && (
                     <Box
                        onClick={() => toggleDrawer()}
                        sx={{
                           color: theme.palette.primary.main,
                           fontSize: "1.8rem",
                           paddingTop: "8px",
                        }}
                     >
                        <CgMenuRightAlt />
                        {/* <DehazeIcon sx={{ color: "primary.main" }} /> */}
                     </Box>
                  )}
               </Box>
            </CustomToolbar>
         </AppBar>
         {matches && (
            <Drawer
               sx={{ backgroundColor: "background.sidebar" }}
               variant="permanent"
               open={open}
            >
               <DrawerHeader alignItems="center">
                  {open ? (
                     <img
                        src={logo}
                        alt="logo"
                        className="eazycrm-logo"
                        onClick={() => navigate("/")}
                     />
                  ) : (
                     <div
                        className="e-logo"
                        onClick={() => navigate("/")}
                     ></div>
                  )}

                  {/* {open ? (
              <Typography sx={{ color: "text.primary" }}>CRM</Typography>
            ) : (
              ""
            )} */}

                  {/* <div className="ginger-logo"></div> */}
               </DrawerHeader>
               {/* <Divider /> */}

               <SideBarList open={open} handleLogout={handleLogout} />
               {/* <List>
                  <ListItem disablePadding sx={{ display: "block" }}>
                     <ListItemButton
                        onClick={() => navigate("/")}
                        sx={{
                           "& .MuiListItemIcon-root": {
                              minWidth: "30px !important",
                              height: "40px",
                              alignItems: "center",
                           },
                        }}
                     >
                        <ListItemIcon
                           sx={{
                              color: "primary.main",
                              "& .MuiSvgIcon-root": {
                                 fontSize: open ? "18px" : "30px",
                              },
                           }}
                        >
                           <img
                              src={HomeIcon}
                              alt="Dashboard"
                              style={{
                                 filter: isDarkMode
                                    ? "invert(1) brightness(10) saturate(100%)"
                                    : "none",
                              }}
                           />
                        </ListItemIcon>
                        <ListItemText
                           sx={{
                              opacity: open ? 1 : 0,
                              color: "primary.main",
                              fontSize: "15px",
                           }}
                           primary="Dashboard"
                        />
                     </ListItemButton>
                  </ListItem>

                  <ListItemButton
                     onClick={() => navigate("/chat")}
                     sx={{
                        "& .MuiListItemIcon-root": {
                           minWidth: "30px !important",
                           height: "40px",
                           alignItems: "center",
                        },
                     }}
                  >
                     <ListItemIcon
                        sx={{
                           color: "primary.main",
                           "& .MuiSvgIcon-root": {
                              fontSize: open ? "18px" : "30px",
                           },
                        }}
                     >
                        <img
                           src={MessageIcon}
                           alt="Dashboard"
                           style={{
                              filter: isDarkMode
                                 ? "invert(1) brightness(10) saturate(100%)"
                                 : "none",
                           }}
                        />
                     </ListItemIcon>
                     <ListItemText
                        sx={{
                           opacity: open ? 1 : 0,
                           color: "primary.main",
                           fontSize: "15px",
                        }}
                        primary="Inbox"
                     />
                  </ListItemButton>
                  <ListItemButton
                     onClick={hanldeOpenTemplates}
                     sx={{
                        "& .MuiListItemIcon-root": {
                           minWidth: "30px !important",
                           height: "40px",
                           alignItems: "center",
                        },
                     }}
                  >
                     <ListItemIcon
                        sx={{
                           color: "primary.main",
                           "& .MuiSvgIcon-root": {
                              fontSize: open ? "18px" : "30px",
                           },
                        }}
                     >
                        <FolderOpenIcon />
                     </ListItemIcon>
                     <ListItemText
                        sx={{
                           opacity: open ? 1 : 0,
                           color: "primary.main",
                           fontSize: "15px",
                        }}
                        primary="Templates"
                     />
                  </ListItemButton>
                  <Collapse in={openTemplates} timeout="auto" unmountOnExit>
                     <List component="div" disablePadding>
                        <SubListItem
                           sx={{ pl: 4 }}
                           onClick={() => navigate("/templates")}
                        >
                           <ListItemIcon
                              sx={{
                                 color: "primary.main",
                                 "& .MuiSvgIcon-root": {
                                    fontSize: "18px",
                                 },
                              }}
                           >
                              <FormatListBulletedIcon />
                           </ListItemIcon>
                           <ListItemText
                              sx={{ color: "primary.main" }}
                              primary="Templates List"
                           />
                        </SubListItem>
                        <SubListItem
                           sx={{ pl: 4 }}
                           onClick={() => navigate("/create-template")}
                        >
                           <ListItemIcon
                              sx={{
                                 color: "primary.main",
                                 "& .MuiSvgIcon-root": {
                                    fontSize: "18px",
                                 },
                              }}
                           >
                              <CreateNewFolderIcon />
                           </ListItemIcon>
                           <ListItemText
                              sx={{ color: "primary.main" }}
                              primary="Create Templates"
                           />
                        </SubListItem>
                        <SubListItem
                           sx={{ pl: 4 }}
                           onClick={() => navigate("/scheduled-messages")}
                        >
                           <ListItemIcon
                              sx={{
                                 color: "primary.main",
                                 "& .MuiSvgIcon-root": {
                                    fontSize: "18px",
                                 },
                              }}
                           >
                              <CreateNewFolderIcon />
                           </ListItemIcon>
                           <ListItemText
                              sx={{ color: "primary.main" }}
                              primary="Scheduled Messages"
                           />
                        </SubListItem>
                     </List>
                  </Collapse>
                  <ListItemButton
                     onClick={handleOpenReports}
                     sx={{
                        "& .MuiListItemIcon-root": {
                           minWidth: "30px !important",
                           height: "40px",
                           alignItems: "center",
                        },
                     }}
                  >
                     <ListItemIcon
                        sx={{
                           color: "primary.main",
                           "& .MuiSvgIcon-root": {
                              fontSize: open ? "18px" : "30px",
                           },
                        }}
                     >
                        {" "}
                        <ArticleIcon />
                     </ListItemIcon>
                     <ListItemText
                        sx={{
                           opacity: open ? 1 : 0,
                           color: "primary.main",
                           fontSize: "15px",
                        }}
                        primary="Reports"
                     />
                  </ListItemButton>
                  <Collapse in={openReports} timeout="auto" unmountOnExit>
                     <List component="div" disablePadding>
                        <SubListItem
                           sx={{ pl: 4 }}
                           onClick={() => navigate("/report/bulk-send")}
                        >
                           <ListItemIcon
                              sx={{
                                 color: "primary.main",
                                 "& .MuiSvgIcon-root": {
                                    fontSize: "18px",
                                 },
                              }}
                           >
                              <FormatListBulletedIcon />
                           </ListItemIcon>
                           <ListItemText
                              sx={{ color: "primary.main" }}
                              primary="Bulk Send Report"
                           />
                        </SubListItem>
                     </List>
                  </Collapse>
                  <ListItemButton
                     onClick={handleOpenInsights}
                     sx={{
                        "& .MuiListItemIcon-root": {
                           minWidth: "30px !important",
                           height: "40px",
                           alignItems: "center",
                        },
                     }}
                  >
                     <ListItemIcon
                        sx={{
                           color: "primary.main",
                           "& .MuiSvgIcon-root": {
                              fontSize: open ? "18px" : "30px",
                           },
                        }}
                     >
                        <img
                           src={GrowthIcon}
                           alt="Dashboard"
                           style={{
                              filter: isDarkMode
                                 ? "invert(1) brightness(10) saturate(100%)"
                                 : "none",
                           }}
                        />
                     </ListItemIcon>
                     <ListItemText
                        sx={{
                           opacity: open ? 1 : 0,
                           color: "primary.main",
                           fontSize: "15px",
                        }}
                        primary="Insights"
                     />
                  </ListItemButton>
                  <Collapse in={openInsights} timeout="auto" unmountOnExit>
                     <List component="div" disablePadding>
                        <SubListItem
                           sx={{ pl: 4 }}
                           onClick={() => navigate("/insights/conversations")}
                        >
                           <ListItemIcon
                              sx={{
                                 color: "primary.main",
                                 "& .MuiSvgIcon-root": {
                                    fontSize: open ? "18px" : "30px",
                                 },
                              }}
                           >
                              <FontAwesomeIcon icon="fa-brands fa-whatsapp" />
                           </ListItemIcon>
                           <ListItemText
                              sx={{
                                 opacity: open ? 1 : 0,
                                 color: "primary.main",
                                 fontSize: "15px",
                              }}
                              primary="Conversation"
                           />
                        </SubListItem>
                        <SubListItem
                           sx={{ pl: 4 }}
                           onClick={() => navigate("/insights/messages")}
                        >
                           <ListItemIcon
                              sx={{
                                 color: "primary.main",
                                 "& .MuiSvgIcon-root": {
                                    fontSize: open ? "18px" : "30px",
                                 },
                              }}
                           >
                              <FontAwesomeIcon icon="fa-brands fa-whatsapp" />
                           </ListItemIcon>
                           <ListItemText
                              sx={{
                                 opacity: open ? 1 : 0,
                                 color: "primary.main",
                                 fontSize: "15px",
                              }}
                              primary="Messages"
                           />
                        </SubListItem>
                     </List>
                  </Collapse>
                  <ListItemButton
                     onClick={() => navigate("/users")}
                     sx={{
                        "& .MuiListItemIcon-root": {
                           minWidth: "30px !important",
                           height: "40px",
                           alignItems: "center",
                        },
                     }}
                  >
                     <ListItemIcon
                        sx={{
                           color: "primary.main",
                           "& .MuiSvgIcon-root": {
                              fontSize: open ? "18px" : "30px",
                           },
                        }}
                     >
                        <img
                           src={AddUser}
                           alt="Dashboard"
                           style={{
                              filter: isDarkMode
                                 ? "invert(1) brightness(10) saturate(100%)"
                                 : "none",
                           }}
                        />
                     </ListItemIcon>
                     <ListItemText
                        sx={{
                           opacity: open ? 1 : 0,
                           color: "primary.main",
                           fontSize: "15px",
                        }}
                        primary="Users"
                     />
                  </ListItemButton>
                  <ListItemButton
                     onClick={() => navigate("/permissions")}
                     sx={{
                        "& .MuiListItemIcon-root": {
                           minWidth: "30px !important",
                           height: "40px",
                           alignItems: "center",
                        },
                     }}
                  >
                     <ListItemIcon
                        sx={{
                           color: "primary.main",
                           "& .MuiSvgIcon-root": {
                              fontSize: open ? "18px" : "30px",
                           },
                        }}
                     >
                        <img
                           src={BookIcon}
                           alt="Dashboard"
                           style={{
                              filter: isDarkMode
                                 ? "invert(1) brightness(10) saturate(100%)"
                                 : "none",
                           }}
                        />
                      
                     </ListItemIcon>
                     <ListItemText
                        sx={{
                           opacity: open ? 1 : 0,
                           color: "primary.main",
                           fontSize: "15px",
                        }}
                        primary="Roles"
                     />
                  </ListItemButton>

                  <ListItemButton
                     onClick={handleOpenChannel}
                     sx={{
                        "& .MuiListItemIcon-root": {
                           minWidth: "30px !important",
                           height: "40px",
                           alignItems: "center",
                        },
                     }}
                  >
                     <ListItemIcon
                        sx={{
                           color: "primary.main",
                           "& .MuiSvgIcon-root": {
                              fontSize: open ? "18px" : "30px",
                           },
                        }}
                     >
                        {" "}
                        <ArticleIcon />
                     </ListItemIcon>
                     <ListItemText
                        sx={{
                           opacity: open ? 1 : 0,
                           color: "primary.main",
                           fontSize: "15px",
                        }}
                        primary="Channels"
                     />
                  </ListItemButton>

                  <Collapse in={openChannels} timeout="auto" unmountOnExit>
                     <List component="div" disablePadding>
                        <SubListItem
                           sx={{ pl: 4 }}
                           onClick={() => navigate("/whatsapp-channel")}
                        >
                           <ListItemIcon
                              sx={{
                                 color: "primary.main",
                                 "& .MuiSvgIcon-root": {
                                    fontSize: open ? "18px" : "30px",
                                 },
                              }}
                           >
                              <FontAwesomeIcon icon="fa-brands fa-whatsapp" />
                           </ListItemIcon>
                           <ListItemText
                              sx={{
                                 opacity: open ? 1 : 0,
                                 color: "primary.main",
                                 fontSize: "15px",
                              }}
                              primary="Whatsapp"
                           />
                        </SubListItem>
                        <SubListItem
                           sx={{ pl: 4 }}
                           onClick={() =>
                              navigate("/whatsapp-business-account")
                           }
                        >
                           <ListItemIcon
                              sx={{
                                 color: "primary.main",
                                 "& .MuiSvgIcon-root": {
                                    fontSize: open ? "18px" : "30px",
                                 },
                              }}
                           >
                              <FontAwesomeIcon icon="fa-brands fa-whatsapp" />
                           </ListItemIcon>
                           <ListItemText
                              sx={{
                                 opacity: open ? 1 : 0,
                                 color: "primary.main",
                                 fontSize: "15px",
                              }}
                              primary="Business Account"
                           />
                        </SubListItem>
                     </List>
                  </Collapse>

                  <ListItemButton>
                     <ListItemText
                        sx={{
                           opacity: open ? 1 : 0,
                           color: "primary.main",
                           fontSize: "15px",
                        }}
                        primary="Settings"
                     />
                  </ListItemButton>

                  <ListItemButton
                     onClick={hanldeOpenSettings}
                     sx={{
                        "& .MuiListItemIcon-root": {
                           minWidth: "30px !important",
                        },
                     }}
                  >
                     <ListItemIcon
                        sx={{
                           color: "primary.main",
                           "& .MuiSvgIcon-root": {
                              fontSize: open ? "18px" : "30px",
                           },
                        }}
                     >
                        <SettingsRoundedIcon />
                     </ListItemIcon>
                     <CustomText
                        sx={{ color: "primary.main", opacity: open ? 1 : 0 }}
                     >
                        Settings
                     </CustomText>
                  </ListItemButton>

                  <Collapse in={openSettings} timeout="auto" unmountOnExit>
                     <List component="div" disablePadding>
                        <SubListItem
                           sx={{ pl: 4 }}
                           onClick={() => navigate("/conversation-labels")}
                        >
                           <ListItemIcon
                              sx={{
                                 color: "primary.main",
                                 "& .MuiSvgIcon-root": {
                                    fontSize: "18px",
                                 },
                              }}
                           >
                              <LabelIcon />
                           </ListItemIcon>
                           <ListItemText
                              sx={{
                                 color: "primary.main",
                                 opacity: open ? 1 : 0,
                              }}
                              primary="Conversation Labels"
                           />
                        </SubListItem>
                        <SubListItem
                           sx={{ pl: 4 }}
                           onClick={() => navigate("/groups")}
                        >
                           <ListItemIcon
                              sx={{
                                 color: "primary.main",
                                 "& .MuiSvgIcon-root": {
                                    fontSize: "18px",
                                 },
                              }}
                           >
                              <CreateNewFolderIcon />
                           </ListItemIcon>
                           <ListItemText
                              sx={{
                                 color: "primary.main",
                                 opacity: open ? 1 : 0,
                              }}
                              primary="Groups"
                           />
                        </SubListItem>
                     </List>
                  </Collapse>
               
                  <ListItemButton
                     onClick={() => handleLogout()}
                     sx={{
                        "& .MuiListItemIcon-root": {
                           minWidth: "30px !important",
                        },
                     }}
                  >
                     <ListItemIcon
                        sx={{
                           color: "#ff0000",
                           "& .MuiSvgIcon-root": {
                              fontSize: open ? "18px" : "30px",
                           },
                        }}
                     >
                        <LogoutIcon />
                     </ListItemIcon>
                     <CustomText
                        sx={{ color: "#ff0000", opacity: open ? 1 : 0 }}
                     >
                        Logout
                     </CustomText>
                  </ListItemButton>
               </List> */}
            </Drawer>
         )}
      </>
   );
});

export default Sidebar;
